import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import AlertIcon from '../assets/svg/ss/bell.svg'
import lstrings from '../lstrings.js'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MuiAlert from '@material-ui/lab/Alert'
import MeasureIcon from '../assets/svg/ss/measure-tape.svg'
import axios from 'axios'
import config from '../config.json'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import Snackbar from '@material-ui/core/Snackbar'
import cloneDeep from 'lodash/cloneDeep'
import Slide from '@material-ui/core/Slide'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import ConfirmationDialog from '../components/confirmationDialog'
import AddSerial from './addSerial'
import Chip from '@material-ui/core/Chip'
import Utils from './utils'

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />
}

function EnhancedTableHead(props) {
	const dir = document.getElementsByTagName('html')[0].getAttribute('dir')
	const setDir = dir === 'rtl' ? true : false

	const headCells = [
		{ id: 'name', numeric: false, disablePadding: false, label: 'Name' },
		{ id: 'description', numeric: false, disablePadding: false, label: 'Description' },
		{ id: 'qtyinstock', numeric: false, disablePadding: false, label: 'Qty In Stock' },
		{ id: 'qty_for_project', numeric: false, disablePadding: false, label: 'Qty For Project' },
		{ id: 'qty_released_for_project', numeric: false, disablePadding: false, label: 'Qty Released For Project' },
		{ id: 'qty', numeric: false, disablePadding: false, label: ' Requested Qty' },
		{ id: 'serials', numeric: false, disablePadding: false, label: ' Serials' },
		{ id: 'actions', numeric: false, disablePadding: false, label: ' Actions' }
	]
	const { classes, order, orderBy, numSelected, rowCount, onRequestSort } = props
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}
	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align={!setDir ? 'left' : 'right'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	},
	textarea: {
		resize: 'both'
	},
	title: {
		marginLeft: 10
	},
	paper: {
		width: '100%',
		marginBottom: theme.spacing(1),
		paddingLeft: 20,
		paddingRight: 20
	}
})

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography className={classes.title} variant='h6'>
				{children}
			</Typography>
		</MuiDialogTitle>
	)
})

const DialogContent = withStyles(theme => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions)

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

export default function ReleaseMaterialIndent(props) {
	const dir = document.getElementsByTagName('html')[0].getAttribute('dir')

	const useStyles = makeStyles(theme => ({
		root: {
			width: 'calc(100%)'
		},
		paper: {
			width: '100%',
			marginBottom: theme.spacing(2),
			paddingLeft: 20,
			paddingRight: 20
		},
		papernew: {
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[2],
			display: 'flex',
			flexDirection: 'column',
			position: 'relative',
			outline: 'none',
			// padding: '10px 20px',
			width: '100%',
			borderRadius: '5px',
			overflow: 'auto',
			depth: 1
			// marginTop: '10px',
			// marginBottom: '10px',
		},
		grid: {
			padding: theme.spacing(2),
			textAlign: 'center',
			color: theme.palette.text.secondary,
			marginBottom: '10px'
		},
		inputFields: {
			marginTop: 10
		},
		submit: {
			display: 'flex',
			justifyContent: 'flex-end'
			// marginTop: '15px',
			// margin: '5px',
		},
		formControl: {
			marginTop: theme.spacing(1),
			minWidth: 300
		},
		selectEmpty: {
			marginTop: theme.spacing(2)
		},
		smalltable: {
			minWidth: 150
		},
		container: {
			// maxHeight: 300,
		},
		backdrop: {
			zIndex: 1000000,
			color: '#fff'
		}
	}))

	const classes = useStyles()

	const [showError, setShowError] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState(null)

	const [current, setCurrent] = React.useState(-1)
	const [order, setOrder] = React.useState('asc')
	const [orderBy, setOrderBy] = React.useState('calories')

	const [currentWarehouse, setCurrentWarehouse] = React.useState(-1)
	const [current_warehouse_error, set_current_warehouse_error] = React.useState(null)

	const [contactingServer, setContactingServer] = React.useState(false)
	const [showBackDrop, setShowBackDrop] = React.useState(false)

	const [items, set_items] = React.useState([])
	const [allItems, set_allItems] = React.useState([])
	const [uoms, set_uoms] = React.useState([])
	const [warehouseStocks, setWarehouseStocks] = React.useState([])
	const [projectStocks, setProjectStocks] = React.useState([])

	const [all_serials, set_all_serials] = React.useState({})

	const [showAddSerial, setShowAddSerial] = React.useState(false)
	const [currentMaterial, setCurrentMaterial] = React.useState(null)

	const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(false)

	async function getWarehouseInventory() {
		try {
			setShowBackDrop(true)
			let url = config['baseurl'] + '/api/storedmaterial/specificlist?warehouse=' + props.warehouse._id
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			let postObj = {}
			postObj['materials'] = props.currentMaterialIndent.indent.materials

			const { data } = await axios.post(url, postObj)
			console.log('getWarehouseInventory: ', data)
			setWarehouseStocks(data.list)

			setShowBackDrop(false)
		} catch (e) {
			setShowBackDrop(false)
			if (e.response) {
				setErrorMessage(e.response.data.message)
			} else {
				setErrorMessage('Error in getting list')
			}
			setShowError(true)
		}
	}

	async function getProjectInventory() {
		try {
			let ids = []
			props.currentMaterialIndent.indent.materials.forEach(material => {
				ids.push(material.item)
			})
			setShowBackDrop(true)
			let url = config['baseurl'] + `/api/project/material-project-qty?_id=${props.currentMaterialIndent.project._id}&mats=${ids.join(',')}`
			console.log('url: ', url)
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')

			const { data } = await axios.get(url)
			console.log('getProjectInventory: ', data)
			console.log('-------------------------------')
			setProjectStocks(data.values)

			setShowBackDrop(false)
		} catch (e) {
			setShowBackDrop(false)
			if (e.response) {
				setErrorMessage(e.response.data.message)
			} else {
				setErrorMessage('Error in getting list')
			}
			setShowError(true)
		}
	}

	const set_serial_number_new = (value, index) => {
		var newItems = {}
		for (var key in all_serials) newItems[key] = all_serials[key]

		newItems[index].serial_number_new = value
		set_all_serials(newItems)
	}

	const addSerials = (value, index) => {
		if (value.length == 0) return

		let serials = []
		for (let s = 0; s < all_serials[index].serial_numbers.length; ++s) {
			let serialRange = all_serials[index].serial_numbers[s].split(',')
			if (serialRange.length == 1) {
				/// Its a single Serial
				serials.push(serialRange[0].toString())
			} else if (serialRange.length == 2) {
				// Serial range
				let range1Splits = Utils.getSerialSplits(serialRange[0])
				let range2Splits = Utils.getSerialSplits(serialRange[1])

				console.log('------------------------')
				console.log(range1Splits)
				console.log(range2Splits)

				for (let k = parseInt(range1Splits['numberPart']); k <= parseInt(range2Splits['numberPart']); ++k)
					serials.push(range1Splits['letterPart'].toString() + k.toString())
			}
		}

		{
			let serialRange = value.split(',')
			if (serialRange.length == 1) {
				/// Its a single Serial
				for (let i = 0; i < serials.length; ++i) {
					if (serials[i] == serialRange[0].toString()) {
						setErrorMessage('Duplicate serials')
						setShowError(true)
						return
					}
				}
			} else if (serialRange.length == 2) {
				// Serial range
				let range1Splits = Utils.getSerialSplits(serialRange[0])
				let range2Splits = Utils.getSerialSplits(serialRange[1])

				if (
					parseInt(range1Splits['numberPart']) == parseInt(range2Splits['numberPart']) ||
					parseInt(range1Splits['numberPart']) > parseInt(range2Splits['numberPart'])
				) {
					setErrorMessage('Not a range of serials')
					setShowError(true)
					return
				}

				for (let k = parseInt(range1Splits['numberPart']); k <= parseInt(range2Splits['numberPart']); ++k) {
					for (let i = 0; i < serials.length; ++i) {
						if (serials[i] == range1Splits['letterPart'].toString() + k.toString()) {
							setErrorMessage('Duplicate serials')
							setShowError(true)
							return
						}
					}
				}
			}
		}

		var newItems = {}
		for (var key in all_serials) newItems[key] = all_serials[key]
		newItems[index].serial_numbers.push(value)
		newItems[index].serial_number_new = ''

		serials = []
		for (let s = 0; s < newItems[index].serial_numbers.length; ++s) {
			let serialRange = newItems[index].serial_numbers[s].split(',')
			if (serialRange.length == 1) {
				/// Its a single Serial
				serials.push(serialRange[0].toString())
			} else if (serialRange.length == 2) {
				// Serial range
				let range1Splits = Utils.getSerialSplits(serialRange[0])
				let range2Splits = Utils.getSerialSplits(serialRange[1])

				console.log('------------------------')
				console.log(range1Splits)
				console.log(range2Splits)

				for (let k = parseInt(range1Splits['numberPart']); k <= parseInt(range2Splits['numberPart']); ++k)
					serials.push(range1Splits['letterPart'].toString() + k.toString())
			}
		}

		newItems[index].qty = serials.length
		newItems[index].serials = serials

		set_all_serials(newItems)
	}

	const handleSerialDelete = (index, chipindex) => {
		var newItems = {}
		for (var key in all_serials) newItems[key] = all_serials[key]
		newItems[index].serial_numbers.splice(chipindex, 1)
		newItems[index].serial_number_new = ''

		let serials = []
		if (newItems[index].serial_numbers.length > 0) {
			for (let s = 0; s < newItems[index].serial_numbers.length; ++s) {
				let serialRange = newItems[index].serial_numbers[s].split(',')
				if (serialRange.length == 1) {
					/// Its a single Serial
					serials.push(parseInt(serialRange[0]))
				} else if (serialRange.length == 2) {
					// Serial range
					let range1Splits = Utils.getSerialSplits(serialRange[0])
					let range2Splits = Utils.getSerialSplits(serialRange[1])

					console.log('------------------------')
					console.log(range1Splits)
					console.log(range2Splits)

					for (let k = parseInt(range1Splits['numberPart']); k <= parseInt(range2Splits['numberPart']); ++k)
						serials.push(range1Splits['letterPart'].toString() + k.toString())
				}
			}
		}

		newItems[index].qty = serials.length
		newItems[index].serials = serials

		set_all_serials(newItems)
	}

	const handleReject = async () => {
		setShowConfirmationDialog(true)
	}

	const handleRelease = async () => {
		setShowError(false)
		set_current_warehouse_error(null)

		try {
			setShowBackDrop(true)
			let url = config['baseurl'] + '/api/materialindent/release'

			let postObj = {}
			postObj['id'] = props.currentMaterialIndent.indent._id

			let releaseSerials = {}
			console.log(warehouseStocks)
			for (let i = 0; i < props.currentMaterialIndent.indent.materials.length; ++i) {
				let material = props.currentMaterialIndent.indent.materials[i]
				console.log(material)
				let stockMat = null
				for (let j = 0; j < warehouseStocks.length; ++j) {
					if (warehouseStocks[j].material._id == material.item) {
						stockMat = warehouseStocks[j].material
						break
					}
				}
				console.log(stockMat)
				if (stockMat.serials_based == 0) continue

				let serials = all_serials[material.item]
				releaseSerials[material.item] = serials
				if (serials.qty != serials.qty_needed) {
					setErrorMessage('Add Serials for the requested qty')
					setShowError(true)
					setShowBackDrop(false)
					return
				}
			}

			postObj['all_serials'] = releaseSerials
			console.log(postObj)

			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')

			let response = await axios.post(url, postObj)
			await Utils.waitTillTransactionComplete(response.data.transaction)
			response = await Utils.getTransactionResponse(response.data.transaction)
			console.log(response)

			if (response.data.status == 200) {
				setShowBackDrop(false)
				props.closeAction()
			} else {
				setErrorMessage(response.data.data.message)
				setShowError(true)
				setShowBackDrop(false)
			}
		} catch (e) {
			if (e.response) {
				console.log('Error in releasing')
				setErrorMessage(e.response.data['message'])
			} else {
				console.log('Error in releasing')
				setErrorMessage('Error in creating: ', e.message)
			}
			setShowError(true)
			setShowBackDrop(false)
		}
	}

	const rejectReleaseMaterialIndent = async () => {
		try {
			setShowBackDrop(true)
			let url = config['baseurl'] + '/api/materialindent/reject'

			let postObj = {}
			postObj['id'] = props.currentMaterialIndent.indent._id

			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')

			const response = await axios.post(url, postObj)
			console.log(response)

			props.closeAction()
		} catch (e) {
			if (e.response) {
				console.log('Error in rejecting material indent')
				setErrorMessage(e.response.data['message'])
			} else {
				console.log('Error in rejecting material indent')
				setErrorMessage('Error in rejecting material indent: ', e.message)
			}
			setShowError(true)
		}

		setShowBackDrop(false)
	}

	const handleCloseSnackbar = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setShowError(false)
	}

	const handleCloseBackDrop = () => {}

	const handleClick = (event, index) => {
		setCurrent(index)
	}

	useEffect(() => {
		let srls = {}
		props.currentMaterialIndent.indent.materials.forEach(material => {
			console.log(material)
			srls[material.item] = { serial_numbers: [], serial_number_new: '', qty: 0, qty_needed: material.qty }
		})
		set_all_serials(srls)
		console.log(srls)

		getWarehouseInventory()
		getProjectInventory()

		console.log(props.currentMaterialIndent.indent.materials)
	}, [])

	const getProjectQty = id => {
		for (let i = 0; i < projectStocks.length; ++i) {
			if (projectStocks[i].material === id) {
				return projectStocks[i].project_qty
			}
		}
		return null
		// console.log('project qty: ', projectStocks.filter(item=> item.material === id)[0].project_qty.id);
		//    return projectStocks[id];
	}

	const getReleasedQty = id => {
		for (let i = 0; i < projectStocks.length; ++i) {
			if (projectStocks[i].material === id) {
				return projectStocks[i].released_qty
			}
		}
		return null
		// console.log('release qty: ', projectStocks.filter(item=> item.material === id)[0].released_qty.id);
		// return projectStocks[id];
	}
	const handleWarehouseChange = event => {
		setCurrentWarehouse(event.target.value)
		set_current_warehouse_error(null)
	}

	const set_item_qty_for = (value, index) => {
		let newItems = [...items]
		newItems[index].qtyToOrder = value
		set_items(newItems)
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const getItem = id => {
		for (let i = 0; i < warehouseStocks.length; ++i) {
			if (warehouseStocks[i].material._id === id) {
				return warehouseStocks[i]
			}
		}
		return null
	}

	const noConfirmationDialogAction = async () => {
		console.log('noConfirmationDialogAction')
		setShowConfirmationDialog(false)
	}

	const yesConfirmationDialogAction = async () => {
		console.log('yesConfirmationDialogAction')
		setShowConfirmationDialog(false)

		rejectReleaseMaterialIndent()
	}

	// const addSerials = (row) => {

	//     // all_serials[row._id] =
	//     console.log(row.material._id);
	//     console.log(all_serials);

	//     setCurrentMaterial(row);
	//     setShowAddSerial(true);
	// }

	const closeNewDialogAction = () => {
		setShowAddSerial(false)
	}

	const onNewSaved = () => {
		setShowAddSerial(false)
	}

	const getReleasedSerials = materialid => {
		let serials = '[0]'
		let released_items = props.currentMaterialIndent.transaction.released_items
		for (let i = 0; i < released_items.length; ++i) {
			if (released_items[i].material == materialid) {
				for (let k = 0; k < released_items[i].serials.length; ++k) {
					if (serials.length > 0) serials += ', '
					serials += released_items[i].serials[k]
				}
				break
			}
		}

		return serials
	}

	return (
		<div>
			<Dialog
				fullScreen
				TransitionComponent={Transition}
				onClose={props.noConfirmationDialogAction}
				aria-labelledby='customized-dialog-title'
				open={true}
			>
				<DialogTitle id='alert-dialog-title'>
					{' '}
					{(props.materialReleased ? 'Released Material Indent : ' : 'Release Material Indent : ') +
						props.currentMaterialIndent.indent.code}
				</DialogTitle>
				<DialogContent>
					<Paper className={classes.paper}>
						<TableContainer className={classes.container}>
							<Table className={classes.smalltable} stickyHeader aria-labelledby='tableTitle' size='small' aria-label='enhanced table'>
								<EnhancedTableHead
									classes={classes}
									numSelected={0}
									order={order}
									orderBy={orderBy}
									onRequestSort={handleRequestSort}
									rowCount={items.length}
								/>

								<TableBody>
									{projectStocks.length > 0 &&
										warehouseStocks.length > 0 &&
										props.currentMaterialIndent.indent.materials.map((row, index) => {
											let disable = true //(parseFloat(row.qtyOrdered) >= parseFloat(row.qty));
											const item = getItem(row.item)
											console.log(item)
											return (
												<TableRow hover tabIndex={-1} key={'' + index} selected={index === current}>
													<TableCell width={200} align={dir === 'rtl' ? 'right' : 'left'}>
														{'' + (index + 1) + '. ' + (item != null ? item.material.name : '')}
													</TableCell>
													<TableCell align={dir === 'rtl' ? 'right' : 'left'}>
														{'' + (item != null ? item.material.description : '')}
													</TableCell>
													<TableCell align={dir === 'rtl' ? 'right' : 'left'}>
														{'' +
															(() => {
																if (item) {
																	if (item.stored) {
																		return item.stored.qty
																	} else {
																		return 0
																	}
																} else {
																	return 0
																}
															})()}
													</TableCell>
													<TableCell align={dir === 'rtl' ? 'right' : 'left'}>{getProjectQty(row.item)}</TableCell>
													<TableCell align={dir === 'rtl' ? 'right' : 'left'}>{getReleasedQty(row.item)}</TableCell>
													<TableCell align={dir === 'rtl' ? 'right' : 'left'}>{'' + row.qty}</TableCell>

													<TableCell align={dir === 'rtl' ? 'right' : 'left'}>
														{props.materialReleased && getReleasedSerials(item.material._id)}
														{!props.materialReleased &&
															warehouseStocks.length > 0 &&
															warehouseStocks[index].material.serials_based == 1 && (
																<div style={{ marginTop: 10 }}>
																	<div>
																		<span>[{all_serials[item.material._id].qty}]: </span>
																		{all_serials[item.material._id].serial_numbers.map((name, chipindex) => {
																			return (
																				<Chip
																					style={{ marginTop: 5, marginRight: 5 }}
																					key={'chip' + chipindex}
																					label={name}
																					clickable
																					variant='outlined'
																					onDelete={() => handleSerialDelete(item.material._id, chipindex)}
																				/>
																			)
																		})}
																	</div>
																</div>
															)}
													</TableCell>
													<TableCell align={dir === 'rtl' ? 'right' : 'left'}>
														{!props.materialReleased &&
															warehouseStocks.length > 0 &&
															warehouseStocks[index].material.serials_based == 1 && (
																<TextField
																	size='small'
																	id={'formControl_end_serial_' + index}
																	style={{ width: '200px' }}
																	value={all_serials[item.material._id].serial_number_new}
																	variant='outlined'
																	onChange={event => {
																		set_serial_number_new(event.target.value, item.material._id)
																	}}
																/>
															)}
														{!props.materialReleased &&
															warehouseStocks.length > 0 &&
															warehouseStocks[index].material.serials_based == 1 && (
																<Button
																	variant='contained'
																	onClick={() => {
																		addSerials(
																			all_serials[item.material._id].serial_number_new,
																			item.material._id
																		)
																	}}
																>
																	Add
																</Button>
															)}
													</TableCell>

													{/* <TableCell align={dir === 'rtl' ? 'right' : 'left'}><Button variant="contained" color="primary" onClick={() => addSerials(item)} >Serials</Button></TableCell> */}
												</TableRow>
											)
										})}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</DialogContent>

				<DialogActions>
					<Button variant='contained' color='primary' onClick={props.closeAction} disabled={contactingServer}>
						{props.materialReleased ? 'back' : 'Cancel'}
					</Button>
					{!props.materialReleased && (
						<Button style={{ marginLeft: 10 }} variant='contained' color='secondary' onClick={handleReject} disabled={contactingServer}>
							Reject
						</Button>
					)}
					{!props.materialReleased && (
						<Button style={{ marginLeft: 10 }} variant='contained' color='primary' onClick={handleRelease} disabled={contactingServer}>
							Release
						</Button>
					)}
				</DialogActions>
			</Dialog>

			<Snackbar open={showError} autoHideDuration={6000} onClose={handleCloseSnackbar}>
				<Alert onClose={handleCloseSnackbar} severity='error'>
					{errorMessage}
				</Alert>
			</Snackbar>

			{showAddSerial && (
				<AddSerial
					closeAction={closeNewDialogAction}
					onNewSaved={onNewSaved}
					material={currentMaterial}
					all_serials={all_serials}
					set_all_serials={set_all_serials}
				/>
			)}

			{showConfirmationDialog && (
				<ConfirmationDialog
					noConfirmationDialogAction={noConfirmationDialogAction}
					yesConfirmationDialogAction={yesConfirmationDialogAction}
					message={'Are you sure you want to reject the Release Material request: '}
					title={'Rejecting Material Request'}
				/>
			)}

			{showBackDrop && (
				<Backdrop className={classes.backdrop} open={showBackDrop} onClick={handleCloseBackDrop}>
					<CircularProgress color='inherit' />
				</Backdrop>
			)}
		</div>
	)
}
