import LocalizedStrings from 'react-localization'

let lstrings = new LocalizedStrings({
	en: {
		Dashboard: 'Dashboard',
		ProjectsDashboard: 'Projects Dashboard',
		Inventory: 'Inventory',
		Procurement: 'Procurement',
		PaymentStatus: 'Payment Status',
		ProjectManagement: 'Project Management',
		Projects: 'Projects',
		AddProject: 'Add Project',
		EditProject: 'Edit Project',
		SupplyVendors: 'Supply Vendors',
		ServiceVendors: 'Service Vendors',
		Warehouse: 'Warehouse',
		Warehouses: 'Warehouses',
		UserManagement: 'User Management',
		Users: 'Users',
		User: 'User',
		AddUser: 'Add User',
		EditUser: 'Edit User',
		Delete: 'Delete',
		Cancel: 'Cancel',
		Save: 'Save',
		Managers: 'Managers',
		DeletingUserConfirmationMessage: 'Do you want to delete the user? Please confirm!',
		DeleteItemConfirmationMessage: 'Do you want to delete the item? Please confirm!',
		DeletingItem: 'Deleting Item',
		DeletingUser: 'Deleting User!',
		Dwa: 'DWA-BOQ Material',
		Materials: 'Materials',
		AddMaterial: 'Add Material',
		ImportMaterial: 'Import Material',
		EditMaterial: 'Edit Material',
		UOMs: 'UOM',
		AddUOMs: 'Add UOM',
		EditUOMs: 'Edit UOM',
		ProductCategory: 'Product Category',
		AddProductCategory: 'Add Product Category',
		EditProductCategory: 'Edit Product Category',
		SupplyVendor: 'Supply Vendor',
		AddSupplyVendor: 'Add Supply Vendor',
		EditSupplyVendor: 'Edit Supply Vendor',
		ServiceVendor: 'Service Vendor',
		AddServiceVendor: 'Add Service Vendor',
		EditServiceVendor: 'Edit Service Vendor',
		Customers: 'Customers',
		AddCustomer: 'Add Customer',
		EditCustomer: 'Edit Customer',
		ExhibitionStalls: 'Exhibition Stalls',
		Auditorium: 'Auditorium - Live',
		NetworkingRooms: 'Networking Rooms',
		Agenda: 'Agenda',
		Exhibitors: 'Exhibitors',
		Attendees: 'Delegates',
		MentoringRooms: 'Mentoring Rooms',
		Help: 'Help',
		Spaces: 'Spaces',
		Catalogues: 'Catalogues',
		Misc: 'Misc',
		EventFeed: 'EventFeed',
		Manage: 'Manage',
		SalesPanel: 'Sales Panel',
		SalesPersons: 'Sales Persons',
		AddSalesPerson: 'Add SalesPerson',
		ExhibitorPanel: 'Exhibitor Panel',
		EditStall: 'Edit Stall Information',
		DelegatesInQueue: 'delegates in Queue',
		Accept: 'Accept',
		Reject: 'Reject',
		Edit: 'Edit',
		Visited: 'Visited',
		NotAuthorizedException: 'Incorrect username or password',
		UsernameExistsException: 'User already registered! Please login or use different email id to create account',
		No: 'No',
		Yes: 'Yes'
	},
	ar: {
		Dashboard: 'Dashboard',
		ProjectsDashboard: 'Projects Dashboard',
		Inventory: 'Inventory',
		Procurement: 'Procurement',
		PaymentStatus: 'Payment Status',
		ProjectManagement: 'Project Management',
		Projects: 'Projects',
		AddProject: 'Add Project',
		EditProject: 'Edit Project',
		SupplyVendors: 'Supply Vendors',
		ServiceVendors: 'Service Vendors',
		Warehouse: 'Warehouse',
		Warehouses: 'Warehouses',
		UserManagement: 'User Management',
		Users: 'Users',
		User: 'User',
		AddUser: 'Add User',
		EditUser: 'Edit User',
		Delete: 'Delete',
		Cancel: 'Cancel',
		Save: 'Save',
		Managers: 'Managers',
		DeletingUserConfirmationMessage: 'Do you want to delete the user? Please confirm!',
		DeletingUser: 'Deleting User!',
		DeleteItemConfirmationMessage: 'Do you want to delete the item? Please confirm!',
		DeletingItem: 'Deleting Item',
		Dwa: 'DWA-BOQ Material',
		Materials: 'Materials',
		AddMaterials: 'Add Materials',
		ImportMaterial: 'Import Material',
		EditMaterial: 'Edit Material',
		UOMs: 'UOM',
		AddUOMs: 'Add UOM',
		EditUOMs: 'Edit UOM',
		ProductCategory: 'Product Category',
		AddProductCategory: 'Add Product Category',
		EditProductCategory: 'Edit Product Category',
		SupplyVendor: 'Supply Vendor',
		AddSupplyVendor: 'Add Supply Vendor',
		EditSupplyVendor: 'Edit Supply Vendor',
		ServiceVendor: 'Service Vendor',
		AddServiceVendor: 'Add Service Vendor',
		EditServiceVendor: 'Edit Service Vendor',
		Customers: 'Customers',
		AddCustomer: 'Add Customer',
		EditCustomer: 'Edit Customer',
		ExhibitionStalls: 'أكشاك المعارض',
		Auditorium: 'قاعة محاضرات - حي',
		NetworkingRooms: 'غرف الشبكات',
		Agenda: 'جدول أعمال',
		Exhibitors: 'العارضون',
		Attendees: 'الحاضرين',
		MentoringRooms: 'Mentoring Rooms',
		Help: 'مساعدة',
		Spaces: 'المساحات',
		Catalogues: 'الكتالوجات',
		Misc: 'Misc',
		EventFeed: 'EventFeed',
		Manage: 'Manage',
		SalesPanel: 'Sales Panel',
		SalesPersons: 'Sales Persons',
		AddSalesPerson: 'Add SalesPerson',
		ExhibitorPanel: 'Exhibitor Panel',
		EditStall: 'Edit Stall Information',
		DelegatesInQueue: 'delegates in Queue',
		Accept: 'Accept',
		Reject: 'Reject',
		Edit: 'Edit',
		Visited: 'Visited',
		NotAuthorizedException: 'Incorrect username or password',
		UsernameExistsException: 'User already registered! Please login or use different email id to create account',
		No: 'No',
		Yes: 'Yes'
	}
})

export default lstrings
