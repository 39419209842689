import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import EnhancedTableToolbar from './enhancedToolbar'
import Checkbox from '@material-ui/core/Checkbox'
import axios from 'axios'
import config from '../config.json'
import Snackbar from '@material-ui/core/Snackbar'
import ListItemText from '@material-ui/core/ListItemText'
import MuiAlert from '@material-ui/lab/Alert'
import lstrings from '../lstrings.js'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import 'date-fns'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import { v4 as uuidv4 } from 'uuid'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import Warehouses from './warehouses'
import Grid from '@material-ui/core/Grid'
import profileLogo from '../assets/svg/ss/profile.svg'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Image, { Shimmer } from 'react-shimmer'
import Utils from './utils'
import SelectProjectManager from './selectProjectManager'

const Joi = require('joi')

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />
}

const handleRequestSort = (event, property) => {
	// const isAsc = orderBy === property && order === 'asc';
	// setOrder(isAsc ? 'desc' : 'asc');
	// setOrderBy(property);
}

const handleSelectAllClick = event => {
	// if (event.target.checked) {
	//   const newSelecteds = rows.map((n) => n.name);
	//   setSelected(newSelecteds);
	//   return;
	// }
	// setSelected([]);
}

function EnhancedTableHead(props) {
	const dir = document.getElementsByTagName('html')[0].getAttribute('dir')
	const setDir = dir === 'rtl' ? true : false

	const headCells = [
		{ id: 'slno', numeric: true, disablePadding: true, label: 'SL NO' },
		{ id: 'name', numeric: false, disablePadding: false, label: 'Manager Name' },
		{ id: 'email', numeric: false, disablePadding: false, label: 'Email' },
		{ id: 'action', numeric: false, disablePadding: false, label: 'Actions' }
	]
	const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}
	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align={!setDir ? 'left' : 'right'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

export default function EditAddProject(props) {
	const dir = document.getElementsByTagName('html')[0].getAttribute('dir')

	const useStyles = makeStyles(theme => ({
		root: {
			width: 'calc(100%)'
		},
		paper: {
			width: '100%',
			marginBottom: theme.spacing(2),
			paddingLeft: 20,
			paddingRight: 20
		},
		papernew: {
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[2],
			display: 'flex',
			flexDirection: 'column',
			position: 'relative',
			outline: 'none',
			padding: '10px 20px',
			width: '100%',
			borderRadius: '5px',
			overflow: 'auto',
			depth: 1,
			marginTop: '10px',
			marginBottom: '10px'
		},
		grid: {
			padding: theme.spacing(2),
			textAlign: 'center',
			color: theme.palette.text.secondary,
			marginBottom: '10px'
		},
		inputFields: {
			marginTop: 10
		},
		submit: {
			display: 'flex',
			justifyContent: 'flex-end',
			marginTop: '15px',
			margin: '5px'
		},
		formControl: {
			marginTop: theme.spacing(1),
			minWidth: 120
		},
		selectEmpty: {
			marginTop: theme.spacing(2)
		},
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff'
		},
		selectEmpty: {
			marginTop: theme.spacing(2)
		},
		addButton: {
			display: 'flex',
			alignItems: 'baseline',
			justifyContent: 'flex-end',
			width: '70%',
			// marginRight: '80px'
			// borderRight: "1px solid #CACACA",
			'@media (max-width: 600px)': {
				width: '300px'
			}
		}
	}))

	const classes = useStyles()
	const [showError, setShowError] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState(null)

	const [name, set_name] = React.useState(props.project.name)
	const [name_error, set_name_error] = React.useState(null)

	const [project, setProject] = React.useState(null)
	const [currentWarehouse, setCurrentWarehouse] = React.useState(props.project.warehouse)
	const [warehouses, set_warehouses] = React.useState([])
	const [customers, setCustomers] = React.useState(props.customers)
	const [customer_error, set_customer_error] = React.useState(null)
	const [currentCustomer, setCurrentCustomer] = React.useState(-1)

	const [remarks, set_remarks] = React.useState(props.project.remark)
	const [remarks_error, set_remarks_error] = React.useState(null)

	const [startDate, handleStartDateChange] = useState(props.project.startdate_conv)
	const [expEndDate, handleExpEndDateChange] = useState(props.project.exp_enddate_conv)

	const [files, set_files] = React.useState(props.project.docs)

	const [contactingServer, setContactingServer] = React.useState(false)

	const [showBackDrop, setShowBackDrop] = React.useState(false)

	const [managers, setManagers] = React.useState([])

	const [order, setOrder] = React.useState('asc')
	const [orderBy, setOrderBy] = React.useState('calories')
	const [selected, setSelected] = React.useState([])

	const [showSelectProjectManager, setShowSelectProjectManager] = React.useState(false)

	async function getProjectDetails() {
		try {
			setShowBackDrop(true)
			let url = config['baseurl'] + '/api/project/details'
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			axios.defaults.headers.common['_id'] = props.project._id
			const { data } = await axios.get(url)
			delete axios.defaults.headers.common['_id']
			console.log('ProjectDetails: project: ', data)

			const dateFns = new DateFnsUtils()
			data.startdate_conv = dateFns.date(data.startdate)
			data.exp_enddate_conv = dateFns.date(data.exp_enddate)

			setProject(data)
		} catch (e) {
			if (e.response) {
				setErrorMessage(e.response.data.message)
			} else {
				setErrorMessage('Error in getting list')
			}
			setShowError(true)
		}

		setShowBackDrop(false)
	}

	async function getManagers() {
		try {
			let url = config['baseurl'] + '/api/project/managers' + '?id=' + project._id
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			const { data } = await axios.get(url)
			console.log(data)
			console.log(data.count)
			// setTotalCount(data.count);
			// console.log(data.list);
			let newRows = []
			for (let i = 0; i < data.count; ++i) {
				newRows.push(Utils.createData(i + 1, data.list[i]))
			}

			setManagers(newRows)
		} catch (e) {
			console.log(e)
			console.log('Error in getting users list')
			if (e.response) {
				setErrorMessage(e.response.message)
			} else {
				setErrorMessage('Error in getting users list')
			}
			setShowError(true)
		}
	}

	async function getCustomerList() {
		try {
			setShowBackDrop(true)
			let url = config['baseurl'] + '/api/customer/list?count=' + 1000 + '&offset=' + 0 + '&search=' + ''
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			const { data } = await axios.get(url)
			// console.log(data);
			// console.log(data.list.docs);
			setCustomers(data.list.docs)
			setCurrentCustomer(getCustomerIndex(project.customer, data.list.docs))
			setShowBackDrop(false)
		} catch (e) {
			setShowBackDrop(false)
			if (e.response) {
				setErrorMessage(e.response.data.message)
			} else {
				setErrorMessage('Error in getting list')
			}
			setShowError(true)
		}
	}

	async function getWarehouseList() {
		try {
			let url = config['baseurl'] + '/api/warehouse/list'
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			const { data } = await axios.get(url)
			let warehouseList = data.list
			set_warehouses(warehouseList)
		} catch (e) {
			console.log('Error in getting users list')
			setErrorMessage('Error in getting users list')
			setShowError(true)
		}
	}

	useEffect(() => {
		getProjectDetails()
	}, [])

	useEffect(() => {
		if (project != null) {
			getCustomerList()
			getWarehouseList()
			getManagers()
		}
	}, [project])

	const refreshAgain = () => {
		setShowSelectProjectManager(false)
		getProjectDetails()
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setShowError(false)
	}

	const handleBreadCrumClick = () => {
		props.history.push('/projects')
	}

	const handleBreadCrumProjectDetailsClick = () => {
		props.history.push('/addproject')
	}

	const handleCancel = () => {
		props.history.push('/projects')
	}

	const handleWarehouseChange = event => {
		console.log(event.target.value)
		setCurrentWarehouse(event.target.value)
	}

	const validateData = () => {
		const schema = Joi.object({
			name: Joi.string()
				.min(2)
				.max(400)
				.required(),
			remarks: Joi.string()
				.min(0)
				.max(4096)
				.required()
		})
		const { error } = schema.validate(
			{
				name: name.trim(),
				remarks: remarks.trim()
			},
			{ abortEarly: false }
		)
		const allerrors = {}
		if (error) {
			for (let item of error.details) allerrors[item.path[0]] = item.message
		}

		return allerrors
	}

	const warehouseById = id => {
		return warehouses.filter(warehouse => warehouse._id === id)[0]?.name
	}

	const handleSave = async e => {
		e.preventDefault()

		set_name_error(null)
		set_remarks_error(null)

		const errors = validateData()

		let errorOccured = false
		if (errors['name']) {
			set_name_error(errors['name'])
			errorOccured = true
		}
		if (errors['remarks']) {
			set_remarks_error(errors['remarks'])
			errorOccured = true
		}

		if (currentCustomer === -1) {
			set_customer_error('Customer is required')
		}

		console.log('1')
		if (errorOccured) return
		console.log('2')
		try {
			setShowBackDrop(true)
			let url = config['baseurl'] + '/api/project/update'

			let postObj = {}
			postObj['name'] = name.trim()
			postObj['warehouse'] = currentWarehouse
			postObj['customer'] = customers[currentCustomer]._id
			postObj['remark'] = remarks.trim()
			postObj['startdate'] = startDate.toUTCString()
			postObj['exp_enddate'] = expEndDate.toUTCString()
			postObj['docs'] = []
			for (let i = 0; i < files.length; ++i) {
				postObj['docs'].push({ name: files[i].name, path: files[i].path })
			}

			console.log('postObj: ', postObj)

			let updateObj = { _id: props.project._id, updateParams: postObj }
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')

			const response = await axios.patch(url, updateObj)

			console.log('4')
			console.log('successfully Saved')
			setShowBackDrop(false)
			props.history.push('/projects')
		} catch (e) {
			console.log('5')
			if (e.response) {
				console.log('Error in creating')
				setErrorMessage(e.response.data['message'])
			} else {
				console.log('Error in creating')
				setErrorMessage('Error in creating: ', e.message)
			}
			setShowError(true)
			setShowBackDrop(false)
		}
	}

	const handleDeleteProject = async () => {
		try {
			setShowBackDrop(true)
			let url = config['baseurl'] + '/api/project/delete'

			let postObj = {}
			postObj['_id'] = props.project._id

			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')

			const response = await axios.post(url, postObj)

			setShowBackDrop(false)
			props.history.push('/projects')
		} catch (e) {
			console.log('5')
			if (e.response) {
				console.log('Error in deleting')
				setErrorMessage(e.response.data['message'])
			} else {
				console.log('Error in deleting')
				setErrorMessage('Error in deleting: ', e.message)
			}
			setShowError(true)
			setShowBackDrop(false)
		}
	}

	const handleDelete = index => {
		console.log('handleDelete: index: ', index)
		let newFiles = [...files]
		newFiles.splice(index, 1)
		set_files(newFiles)
	}

	const handleOpenDoc = index => {
		const file = files[index]
		console.log(file)
		window.open(file.path, '_blank')
	}

	const onFileSelected = event => {
		console.log(event.target.files[0])

		let fileParts = event.target.files[0].name.split('.')
		console.log(fileParts)
		let file = { file: event.target.files[0], name: uuidv4() + '.' + fileParts[1] }

		uploadFile(file)
	}

	const uploadFile = async myfile => {
		setShowBackDrop(true)

		console.log('Preparing the upload')
		let url = config['baseurl'] + '/api/cloud/sign_s3'
		axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
		const profileInfo = JSON.parse(window.localStorage.getItem('profile'))
		try {
			const response = await axios.post(url, {
				fileName: myfile.name,
				fileType: myfile.file.fileType,
				folder: 'project_docs'
			})

			if (response) {
				var returnData = response.data.data.returnData
				var signedRequest = returnData.signedRequest

				// Put the fileType in the headers for the upload
				var options = { headers: { 'x-amz-acl': 'public-read', 'Content-Type': myfile.file.type } }
				try {
					const result = await axios.put(signedRequest, myfile.file, options)

					let newFiles = [...files]
					myfile.path = returnData.url
					myfile.name = myfile.file.name
					console.log('myfile: ', myfile)
					newFiles.push(myfile)
					set_files(newFiles)

					setShowBackDrop(false)

					console.log('Response from s3 Success: ', returnData.url)
				} catch (error) {
					console.log('ERROR: ', JSON.stringify(error))
					setShowBackDrop(false)
					alert('ERROR ' + JSON.stringify(error))
				}
			}
		} catch (error) {
			console.log('error: ', error)
			setShowBackDrop(false)
			alert(JSON.stringify(error))
		}
	}

	const handleCloseBackDrop = () => {}

	const handleCustomerChange = event => {
		setCurrentCustomer(event.target.value)
		set_customer_error(null)
	}

	const getCustomerIndex = (customerId, data) => {
		// console.log(data);
		for (let i = 0; i < data.length; ++i) {
			if (data[i]._id === customerId) return i
		}
		return -1
	}

	const handleAddProjectManager = () => {
		setShowSelectProjectManager(true)
	}

	const handleCloseSelectProject = () => {
		setShowSelectProjectManager(false)
	}

	const handleProjectManagerDelete = async data => {
		setContactingServer(true)
		try {
			let url = config['baseurl'] + '/api/project/removeprojectmanager'

			let postObj = {}
			postObj['_id'] = project._id
			postObj['manager'] = data._id

			console.log('postObj: ', postObj)

			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')

			const updated = await axios.patch(url, postObj)
			console.log(updated)

			refreshAgain()
		} catch (e) {
			if (e.response) {
				console.log('Error in creating new user')
				setErrorMessage(e.response.data['message'])
			} else {
				console.log('Error in creating new user')
				setErrorMessage('Error in creating new user: ', e.message)
			}
			setShowError(true)
			setContactingServer(false)
		}
	}

	const getStringForArray = data => {
		let val = ''
		for (let i = 0; i < data.length; ++i) {
			if (data[i] === 'projectManager' || data[i] === 'deputyManager') {
				if (val.length > 0) val += ', '
				val += data[i]
			}
		}
		return val
	}

	return (
		<div className={clsx(classes.root)}>
			{props.refreshUI && project != null && (
				<div className={classes.paper}>
					<EnhancedTableToolbar title={'Edit Project'} />

					<Breadcrumbs aria-label='breadcrumb'>
						{/* <Link color="inherit" onClick={handleBreadCrumClick}>
              {"Projects"}
            </Link> */}
						<Link color='inherit' onClick={handleBreadCrumProjectDetailsClick}>
							{project.code}
						</Link>
						<Typography color='textPrimary'>{'Edit Project'}</Typography>
					</Breadcrumbs>

					{/* <Paper className={classes.grid}> */}
					<form className={classes.papernew} autoComplete='off' noValidate>
						{/* name */}
						<TextField
							size='small'
							className={classes.inputFields}
							id='formControl_name'
							defaultValue={name}
							label='Project Name *'
							variant='outlined'
							onChange={event => {
								set_name(event.target.value)
								set_name_error(null)
							}}
						/>
						{name_error && (
							<Alert className={classes.alert} severity='error'>
								{' '}
								{name_error}{' '}
							</Alert>
						)}

						{warehouses && (
							<FormControl size='small' variant='outlined' className={classes.formControl}>
								<InputLabel id='customer-select-label'>Warehouse *</InputLabel>
								<Select
									labelId='warehouse-select-label'
									id='warehouse-select-label'
									multiple
									value={currentWarehouse === -1 ? '' : currentWarehouse}
									onChange={handleWarehouseChange}
									label='Warehouse *'
									renderValue={selected => selected.map(w => warehouseById(w)).join(',')}
								>
									{warehouses.map((row, index) => {
										return (
											<MenuItem key={'' + index} value={row._id}>
												<Checkbox checked={currentWarehouse.indexOf(row._id) > -1} />
												<ListItemText primary={row.name} />
											</MenuItem>
										)
									})}
								</Select>
							</FormControl>
						)}
						{name_error && (
							<Alert className={classes.alert} severity='error'>
								{' '}
								{name_error}{' '}
							</Alert>
						)}

						{customers && (
							<FormControl size='small' variant='outlined' className={classes.formControl}>
								<InputLabel id='customer-select-label'>Customer *</InputLabel>
								<Select
									labelId='customer-select-label'
									id='customer-select-label'
									value={currentCustomer === -1 ? '' : currentCustomer}
									onChange={handleCustomerChange}
									label='Customer *'
								>
									{customers.map((row, index) => {
										return (
											<MenuItem key={'' + index} value={index}>
												{row.name}
											</MenuItem>
										)
									})}
								</Select>
							</FormControl>
						)}
						{customer_error && (
							<Alert className={classes.alert} severity='error'>
								{' '}
								{customer_error}{' '}
							</Alert>
						)}

						<TextField
							size='small'
							className={classes.inputFields}
							id='formControl_remarks'
							defaultValue={remarks}
							label='Remarks *'
							variant='outlined'
							multiline
							onChange={event => {
								set_remarks(event.target.value)
								set_remarks_error(null)
							}}
						/>
						{remarks_error && (
							<Alert className={classes.alert} severity='error'>
								{' '}
								{remarks_error}{' '}
							</Alert>
						)}

						<FormControl variant='outlined' size='small' className={classes.formControl}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DatePicker
									size='small'
									label='StartDate'
									inputVariant='outlined'
									format='dd/MM/yyyy'
									value={startDate}
									onChange={handleStartDateChange}
								/>
							</MuiPickersUtilsProvider>
						</FormControl>

						<FormControl variant='outlined' size='small' className={classes.formControl}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<DatePicker
									size='small'
									label='Exp EndDate'
									inputVariant='outlined'
									format='dd/MM/yyyy'
									value={expEndDate}
									onChange={handleExpEndDateChange}
								/>
							</MuiPickersUtilsProvider>
						</FormControl>

						<div style={{ marginTop: 10 }}>
							<div>
								{files.map((file, index) => {
									return (
										<Chip
											style={{ marginTop: 5, marginRight: 5 }}
											key={'chip' + index}
											label={file.name}
											clickable
											variant='outlined'
											onClick={() => handleOpenDoc(index)}
											onDelete={() => handleDelete(index)}
										/>
									)
								})}
							</div>
							<div style={{ marginTop: 5 }}>
								<Button
									style={{ background: '#314293', color: '#FFFFFF' }}
									variant='contained'
									component='label'
									onChange={onFileSelected}
								>
									Upload Document
									<input type='file' hidden />
								</Button>
							</div>
						</div>

						<div className={classes.submit}>
							<Button variant='contained' color='secondary' onClick={handleDeleteProject} disabled={contactingServer}>
								Delete
							</Button>
							<Button style={{ marginLeft: 20 }} variant='contained' color='primary' onClick={handleCancel} disabled={contactingServer}>
								Cancel
							</Button>
							<Button style={{ marginLeft: 10 }} variant='contained' color='primary' onClick={handleSave} disabled={contactingServer}>
								Save
							</Button>
						</div>
					</form>
					<Paper className={classes.grid}>
						<Paper className={classes.grid}>
							<Grid container spacing={2}>
								<Grid item className={classes.totalAttendes}>
									<img src={profileLogo} width='25' alt='' />
									<h1 className={classes.h1}>{managers.length}</h1>
									<span>{'Project Managers'}</span>
								</Grid>
								<Grid item className={classes.addButton}>
									<Button
										onClick={() => handleAddProjectManager()}
										style={{ background: '#314293', color: '#FFFFFF' }}
										variant='contained'
										className={classes.button}
									>
										{'Add Manager'}
									</Button>
								</Grid>
							</Grid>
						</Paper>

						<TableContainer>
							<Table className={classes.table} aria-labelledby='tableTitle' size={'small'} aria-label='enhanced table'>
								<EnhancedTableHead
									classes={classes}
									numSelected={selected.length}
									order={order}
									orderBy={orderBy}
									onSelectAllClick={handleSelectAllClick}
									onRequestSort={handleRequestSort}
									rowCount={managers.length}
								/>

								<TableBody>
									{managers.map((row, index) => {
										// const isItemSelected = isSelected(row.name);
										const labelId = `enhanced-table-checkbox-${index}`
										return (
											<TableRow hover tabIndex={-1} key={row.slno}>
												<TableCell
													align={dir === 'rtl' ? 'right' : 'left'}
													component='th'
													id={labelId}
													scope='row'
													padding='none'
												>
													{row.slno}
												</TableCell>
												<TableCell align={dir === 'rtl' ? 'right' : 'left'}>
													<div className={classes.flex}>
														<Image
															src={profileLogo}
															NativeImgProps={{ className: classes.exhibitor_image, width: 40, height: 40 }}
															style={{ objectFit: 'cover' }}
															fallback={<Shimmer width={50} height={50} />}
														/>

														<span>{row.data.name}</span>
													</div>
												</TableCell>
												<TableCell align={dir === 'rtl' ? 'right' : 'left'}>
													<span>{row.data.email}</span>
													<br></br>
													<span>{row.data.email}</span>
												</TableCell>
												<TableCell>
													<span>{getStringForArray(row.data.role)}</span>
												</TableCell>
												<TableCell align={dir === 'rtl' ? 'right' : 'left'}>
													<div>
														<Button
															onClick={() => handleProjectManagerDelete(row.data)}
															style={{ background: '#314293', color: '#FFFFFF' }}
															variant='contained'
															className={classes.button}
														>
															{lstrings.Delete}
														</Button>
													</div>
												</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>
				</div>
			)}

			{showSelectProjectManager && <SelectProjectManager project={project} close={handleCloseSelectProject} selected={refreshAgain} />}

			<Snackbar open={showError} autoHideDuration={60000} onClose={handleClose}>
				<Alert onClose={handleClose} severity='error'>
					{errorMessage}
				</Alert>
			</Snackbar>

			<Backdrop className={classes.backdrop} open={showBackDrop} onClick={handleCloseBackDrop}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	)
}
