import React from 'react'

const NotFound = () => {
	return (
		<div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'tranform(-50%, -50%)' }}>
			{' '}
			<h1>Not Found</h1>
		</div>
	)
}

export default NotFound
