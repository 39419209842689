import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import EnhancedTableToolbar from './enhancedToolbar'
import config from '../config.json'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import lstrings from '../lstrings'
import ProjectsImage from '../assets/svg/ss/brief-2.svg'
import Link from '@material-ui/core/Link'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import DateFnsUtils from '@date-io/date-fns'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import IconButton from '@material-ui/core/IconButton'
import AddImage from '@material-ui/icons/Add'
import RemoveImage from '@material-ui/icons/Remove'
import SelectPlace from './selectPlace'
import SelectActivity from './selectActivity'
import UpdateActivity from './updateActivity'
import Chip from '@material-ui/core/Chip'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Check from '@material-ui/icons/Check'
import StepConnector from '@material-ui/core/StepConnector'
import SettingsIcon from '@material-ui/icons/Settings'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import VideoLabelIcon from '@material-ui/icons/VideoLabel'
import axios from 'axios'
import { allow } from 'joi'
import { EditLocation, FreeBreakfastOutlined } from '@material-ui/icons'

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />
}

function EnhancedTableHeadSmall(props) {
	const dir = document.getElementsByTagName('html')[0].getAttribute('dir')
	const setDir = dir === 'rtl' ? true : false

	const headCells = [
		{ id: 'name', numeric: false, disablePadding: false, label: props.title },
		{ id: 'rate', numeric: true, disablePadding: false, label: 'Rate (Rs)' },
		{ id: 'qty', numeric: true, disablePadding: false, label: 'Qty' },
		{ id: 'uom', numeric: false, disablePadding: false, label: 'UOM' }
	]

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell, index) => (
					<TableCell key={headCell.id} align={!setDir ? 'left' : 'right'} padding='none' sortDirection={false}>
						{headCell.label}
						{/* {index === 0 && (
                          <IconButton color="primary" aria-label="upload picture" component="span" onClick={props.onClick}>
                              <AddImage />
                          </IconButton>
                      )} */}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

export default function LPDetails(props) {
	const dir = document.getElementsByTagName('html')[0].getAttribute('dir')

	const useStyles = makeStyles(theme => ({
		root: {
			width: 'calc(100%)',
			marginTop: '5em'
		},
		paper: {
			width: '90%',
			margin: '0 auto',
			marginBottom: theme.spacing(2),
			padding: 20
		},
		grid: {
			padding: theme.spacing(2),
			textAlign: 'center',
			color: theme.palette.text.secondary,
			marginBottom: '10px'
		},
		total: {
			textAlign: 'left'
		},
		inputFields: {
			width: '100%',
			margin: '0.5em 0'
		},
		totalDetails: {
			marginRight: '20px'
		},
		totalAttendes: {
			display: 'flex',
			alignItems: 'baseline',
			width: '30%',
			// marginRight: '80px'
			// borderRight: "1px solid #CACACA",
			'@media (max-width: 600px)': {
				width: '300px'
			}
		},
		progress: {
			width: '70%',
			'@media (max-width: 600px)': {
				width: '600px'
			}
		},
		h1: {
			margin: '0px',
			paddingRight: '10px',
			paddingLeft: '10px',
			textAlign: 'center'
		},
		vl: {
			border: '1px solid #CACACA',
			height: ' 40px'
		},
		search: {
			position: 'relative'
		},
		searchIcon: {
			height: '100%',
			position: 'absolute',
			display: 'flex',
			alignItems: 'center'
		},
		inputInput: {
			// width: '90%',
			paddingLeft: '30px',
			paddingRight: '30px',
			borderBottom: '1px solid #CACACA'
		},
		inputRoot: {
			width: '100%'
		},
		button: {
			background: 'white',
			textTransform: 'capitalize'
		},
		exhibitor_image: {
			marginRight: '10px'
		},
		flex: {
			display: 'flex',
			alignItems: 'center'
		},
		dot: {
			height: '10px',
			width: '10px',
			backgroundColor: '#bbb',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginLeft: '7px'
		},
		dotActive: {
			height: '10px',
			width: '10px',
			backgroundColor: '#4287F5',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginLeft: '7px'
		},
		addButton: {
			display: 'flex',
			alignItems: 'baseline',
			justifyContent: 'flex-end',
			width: '70%',
			// marginRight: '80px'
			// borderRight: "1px solid #CACACA",
			'@media (max-width: 600px)': {
				width: '300px'
			}
		},
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff'
		},
		container: {
			maxHeight: 300
		}
	}))

	const { transaction, warehouse, project, createddate_conv } = props.currentLp
	const classes = useStyles()
	const [uoms, set_uoms] = React.useState([])
	const [allItems, set_allItems] = React.useState([])
	const [showError, setShowError] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState(null)
	const [showBackDrop, setShowBackDrop] = React.useState(false)
	const [editMode, setEditMode] = React.useState(false)
	const [items, set_items] = React.useState([])

	const getuomFor = value => {
		let uomId = ''
		for (let i = 0; i < allItems.length; ++i) {
			if (value === allItems[i]._id) {
				uomId = allItems[i].uomId
				break
			}
		}

		for (let i = 0; i < uoms.length; ++i) {
			if (uomId === uoms[i]._id) return uoms[i].name
		}

		return uomId
	}

	async function getUOMList() {
		try {
			let url = config['baseurl'] + '/api/uom/list'
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			const { data } = await axios.get(url)
			set_uoms(data.list)
		} catch (e) {
			console.log('Error in getting UOMs list')
			setErrorMessage('Error in getting UOMs list')
			setShowError(true)
		}
	}

	async function getAllItemList() {
		try {
			let url = config['baseurl'] + '/api/material/list?count=' + 100000 + '&offset=' + 0 + '&search=' + ''
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			const { data } = await axios.get(url)
			console.log(data)

			set_allItems(data.list.docs)
		} catch (e) {
			setErrorMessage('Error in getting all items')
			setShowError(true)
		}
	}

	const getItemNameFor = value => {
		for (let i = 0; i < allItems.length; ++i) {
			if (value === allItems[i]._id) return allItems[i].name
		}
		return value
	}

	useEffect(() => {
		getAllItemList()
		getUOMList()

		let newItems = [...transaction.items]
		set_items(newItems)
	}, [])

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setShowError(false)
	}

	const deleteAction = async () => {
		setShowBackDrop(true)
		try {
			let url = config['baseurl'] + '/api/localpurchase/delete'

			let postObj = {}
			postObj['_id'] = transaction._id
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			const response = await axios.post(url, postObj)
			props.history.goBack()
		} catch (e) {
			console.log(e)
			console.log(e.response)
			setErrorMessage('Error in closing PO')
			setShowError(true)
		}

		setShowBackDrop(false)
	}

	const handleCloseBackDrop = () => {}

	const getStatus = () => {
		if (transaction.deleted) return 'Deleted'
		if (transaction.completed) return 'Completed'

		return 'Open'
	}

	const editAction = () => {
		setEditMode(true)
	}

	const saveAction = async () => {
		try {
			setShowBackDrop(true)
			let url = config['baseurl'] + '/api/localpurchase/update'

			let postObj = {}
			postObj['items'] = items

			console.log('postObj: ', postObj)

			let updateObj = { _id: transaction._id, updateParams: postObj }

			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')

			const updated = await axios.patch(url, updateObj)

			console.log('successfully Saved')
			setShowBackDrop(false)
			setEditMode(false)
		} catch (e) {
			console.log('5')
			if (e.response) {
				console.log('Error in creating')
				setErrorMessage(e.response.data['message'])
			} else {
				console.log('Error in creating')
				setErrorMessage('Error in creating: ', e.message)
			}
			setShowError(true)
			setShowBackDrop(false)
		}
	}

	const set_item_rate_for = (value, index) => {
		let newItems = [...items]
		newItems[index].rate = parseInt(value)
		set_items(newItems)
	}

	const set_item_qty_for = (value, index) => {
		let newItems = [...items]
		newItems[index].qty = parseInt(value)
		set_items(newItems)
	}

	return (
		<div className={clsx(classes.root)}>
			<Typography className={classes.h1} variant='h5' component='div'>
				Local Purchase Details
			</Typography>
			<Paper className={classes.paper}>
				<TextField size='small' className={classes.inputFields} disabled defaultValue={transaction.code} label='LP Code' variant='outlined' />
				<TextField
					size='small'
					className={classes.inputFields}
					disabled
					defaultValue={project.name}
					label='Project Name'
					variant='outlined'
					multiline
				/>
				<TextField
					size='small'
					className={classes.inputFields}
					disabled
					defaultValue={warehouse.name}
					label='WH Name'
					variant='outlined'
					multiline
				/>
				<TextField
					size='small'
					className={classes.inputFields}
					disabled
					defaultValue={createddate_conv}
					label='Created Date'
					variant='outlined'
					multiline
				/>
				<TextField
					size='small'
					className={classes.inputFields}
					disabled
					defaultValue={getStatus()}
					label='Status'
					variant='outlined'
					multiline
				/>
			</Paper>

			<Paper className={classes.paper} style={{ marginTop: 10 }}>
				<TableContainer className={classes.container}>
					<Table className={classes.smalltable} stickyHeader aria-labelledby='tableTitle' size='small' aria-label='enhanced table'>
						<EnhancedTableHeadSmall title='Items' />
						<TableBody>
							{items.map((row, index) => {
								return (
									<TableRow hover tabIndex={-1} key={'' + index}>
										<TableCell align={dir === 'rtl' ? 'right' : 'left'}>
											{'' + (index + 1) + '. ' + (allItems.length > 0 ? getItemNameFor(row.item) : '')}
										</TableCell>
										<TableCell align={dir === 'rtl' ? 'right' : 'left'}>
											<TextField
												size='small'
												id={'formControl_rate_' + index}
												type='number'
												value={row.rate}
												variant='outlined'
												disabled={!editMode}
												onChange={event => {
													set_item_rate_for(event.target.value, index)
												}}
											/>
										</TableCell>
										<TableCell align={dir === 'rtl' ? 'right' : 'left'}>
											<TextField
												size='small'
												id={'formControl_qty_' + index}
												type='number'
												value={row.qty}
												variant='outlined'
												disabled={!editMode}
												onChange={event => {
													set_item_qty_for(event.target.value, index)
												}}
											/>
										</TableCell>
										<TableCell align={dir === 'rtl' ? 'right' : 'left'}>
											{'' + (uoms.length > 0 && allItems.length > 0 ? getuomFor(row.item) : '')}
										</TableCell>
									</TableRow>
								)
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
			<Paper className={classes.paper}>
				{!editMode && (
					<div style={{ textAlign: 'right' }}>
						{transaction.deleted == 0 && transaction.completed == 0 && (
							<Button variant='contained' color='Secondary' style={{ marginRight: 30 }} onClick={() => deleteAction()}>
								Delete
							</Button>
						)}
						{transaction.deleted == 0 && transaction.completed == 0 && (
							<Button variant='contained' color='primary' style={{ marginRight: 10 }} onClick={() => editAction()}>
								Edit
							</Button>
						)}
						<Button variant='contained' color='primary' onClick={() => props.history.goBack()}>
							Cancel
						</Button>
					</div>
				)}
				{editMode && (
					<div style={{ textAlign: 'right' }}>
						<Button variant='contained' color='primary' style={{ marginRight: 10 }} onClick={() => saveAction()}>
							Save
						</Button>
						<Button variant='contained' color='primary' onClick={() => props.history.goBack()}>
							Cancel
						</Button>
					</div>
				)}
			</Paper>
			<Snackbar open={showError} autoHideDuration={60000} onClose={handleClose}>
				<Alert onClose={handleClose} severity='error'>
					{errorMessage}
				</Alert>
			</Snackbar>

			<Backdrop className={classes.backdrop} open={showBackDrop} onClick={handleCloseBackDrop}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	)
}
