import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import LinearProgress from '@material-ui/core/LinearProgress'
import axios from 'axios'
import config from '../config.json'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import DateFnsUtils from '@date-io/date-fns'
import Typography from '@material-ui/core/Typography'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DocumentsFolder from './documentsFolder'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />
}

function createData(slno, data) {
	return { slno, data }
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map(el => el[0])
}

function EnhancedTableHead(props) {
	const dir = document.getElementsByTagName('html')[0].getAttribute('dir')
	const setDir = dir === 'rtl' ? true : false

	const headCells = [
		{ id: 'slno', numeric: true, disablePadding: true, label: 'SL' },
		{ id: 'name', numeric: false, disablePadding: false, label: 'Document Name ' },
		{ id: 'remarks', numeric: false, disablePadding: false, label: 'Remark' },
		{ id: 'uploadeddate', numeric: false, disablePadding: false, label: 'Uploaded Date' },
		{ id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
	]
	const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}
	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align={!setDir ? 'left' : 'right'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
}

export default function ProjectActivityDetails(props) {
	console.log(props)
	const history = useHistory()

	const dir = document.getElementsByTagName('html')[0].getAttribute('dir')

	const useStyles = makeStyles(theme => ({
		root: {
			width: 'calc(100%)'
		},
		paper: {
			width: '100%',
			marginBottom: theme.spacing(2),
			paddingLeft: 20,
			paddingRight: 20
		},
		table: {
			minWidth: 750
		},
		visuallyHidden: {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1
		},
		grid: {
			padding: theme.spacing(2),
			textAlign: 'center',
			color: theme.palette.text.secondary,
			marginBottom: '10px'
		},
		total: {
			textAlign: 'left'
		},
		totalDetails: {
			marginRight: '20px'
		},
		totalAttendes: {
			display: 'flex',
			alignItems: 'baseline',
			width: '30%',
			// marginRight: '80px'
			// borderRight: "1px solid #CACACA",
			'@media (max-width: 600px)': {
				width: '300px'
			}
		},
		progress: {
			width: '70%',
			'@media (max-width: 600px)': {
				width: '600px'
			}
		},
		h1: {
			margin: '0px',
			paddingRight: '10px',
			paddingLeft: '10px'
		},
		vl: {
			border: '1px solid #CACACA',
			height: ' 40px'
		},
		search: {
			position: 'relative'
		},
		searchIcon: {
			height: '100%',
			position: 'absolute',
			display: 'flex',
			alignItems: 'center'
		},
		inputInput: {
			// width: '90%',
			paddingLeft: '30px',
			paddingRight: '30px',
			borderBottom: '1px solid #CACACA'
		},
		inputRoot: {
			width: '100%'
		},
		button: {
			background: 'white',
			textTransform: 'capitalize'
		},
		exhibitor_image: {
			marginRight: '10px'
		},
		flex: {
			display: 'flex',
			alignItems: 'center'
		},
		dot: {
			height: '10px',
			width: '10px',
			backgroundColor: '#bbb',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginLeft: '7px'
		},
		dotActive: {
			height: '10px',
			width: '10px',
			backgroundColor: '#4287F5',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginLeft: '7px'
		},
		addButton: {
			display: 'flex',
			alignItems: 'baseline',
			justifyContent: 'flex-end',
			width: '70%',
			// marginRight: '80px'
			// borderRight: "1px solid #CACACA",
			'@media (max-width: 600px)': {
				width: '300px'
			}
		},
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff'
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
			fontWeight: theme.typography.fontWeightRegular,
			margin: '10px',
			padding: '10px',
			background: 'white',
			boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
		}
	}))

	const classes = useStyles()
	const [page, setPage] = React.useState(0)
	const [dense] = React.useState(true)
	const [showError, setShowError] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState(null)
	const [rows, setRows] = React.useState([])
	const [totalCount, setTotalCount] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [showBackDrop, setShowBackDrop] = React.useState(false)

	const [currentPath, setCurrentPath] = React.useState('/' + props.type)
	const [currentPathArr, setCurrentPathArr] = React.useState([])
	const [allItems, setAllItems] = useState([])

	const [activities, setActivities] = useState([])
	const pageLimits = [10, 25, 50]
	let offset = 0

	async function getList(parent, numberOfRows, search = '') {
		try {
			console.log(props.project)
			let url =
				config['baseurl'] +
				'/api/document/list?project=' +
				props.project._id +
				'&count=' +
				numberOfRows +
				'&offset=' +
				offset +
				'&search=' +
				search +
				'&parent=' +
				parent
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			const { data } = await axios.get(url)
			console.log(data)
			let newRows = []
			setTotalCount(data.list.totalDocs)
			const dateFns = new DateFnsUtils()
			for (let i = 0; i < data.list.docs.length; ++i) {
				data.list.docs[i].createdDate_conv = dateFns.date(data.list.docs[i].createdDate)
				newRows.push(createData(offset + i + 1, data.list.docs[i]))
			}

			setRows(newRows)
		} catch (e) {
			if (e.response) {
				setErrorMessage(e.response.data.message)
			} else {
				setErrorMessage('Error in getting list')
			}
			setShowError(true)
		}
	}

	async function getAllItemList() {
		try {
			// setShowBackDrop(true);
			let url = config['baseurl'] + '/api/material/list?count=' + 10000 + '&offset=' + 0 + '&search='
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			const { data } = await axios.get(url)
			console.log(data)
			// debugger;
			setAllItems(data.list.docs)
		} catch (e) {
			// setShowBackDrop(false);
			console.log('Error in getting all items')
			setErrorMessage('Error in getting all items')
			setShowError(true)
		}
	}
	async function getActivityList() {
		try {
			console.log(props.project)
			let url = config['baseurl'] + '/api/work/listbyproject?project=' + props.project._id + '&count=100' + '&offset=0'
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			const { data } = await axios.get(url)
			console.log(data.list)
			setActivities([data.list[props.activityIndex]])
		} catch (e) {
			if (e.response) {
				setErrorMessage(e.response.data.message)
			} else {
				setErrorMessage('Error in getting list')
			}
			setShowError(true)
		}
	}

	useEffect(() => {
		if (props.project) getActivityList()
		getAllItemList()
	}, [props.project])

	useEffect(() => {
		if (!props.project) return

		setRows([])

		setPage(0)
		offset = 0
		setPath('/' + props.type)
		getList('/' + props.type, rowsPerPage)

		console.log(props.type)
	}, [props.type, props.project])

	const setPath = path => {
		setCurrentPath(path)
		setCurrentPathArr(path.split('/'))
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setShowError(false)
	}

	const BorderLinearProgress = withStyles(theme => ({
		root: {
			height: 10,
			borderRadius: 5
		},
		colorPrimary: {
			backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
		},
		bar: {
			borderRadius: 5,
			backgroundColor: '#1a90ff'
		}
	}))(LinearProgress)

	const handleCloseBackDrop = () => {}

	const getItemNameById = id => {
		let item = allItems.find(item => item._id === id)
		if (item) {
			return item.name
		} else {
			return 'NA'
		}
	}

	return (
		<div className={clsx(classes.root)}>
			<div className={clsx(classes.root)}>
				<div className={classes.paper}>
					<Typography variant='h5' style={{ marginTop: '4em', textAlign: 'center' }}>
						Activities Details
					</Typography>
					<Link to='/project-projectestimates' style={{ marginBottom: '2em' }}>
						{' '}
						Back{' '}
					</Link>
					{activities.map((activity, i) => (
						<TableContainer component={Paper} key={i}>
							<Table className={classes.table} aria-label='simple table'>
								<TableHead>
									<TableRow>
										<TableCell align={dir === 'rtl' ? 'right' : 'left'}>{'Survey Materials'} </TableCell>
										<TableCell align={dir === 'rtl' ? 'right' : 'left'}>{'Qty'} </TableCell>
									</TableRow>
								</TableHead>

								<TableBody>
									{activity &&
										activity.work.survey_materials.map((material, index) => (
											<TableRow hover key={index}>
												<TableCell align={dir === 'rtl' ? 'right' : 'left'}>{getItemNameById(material.item)} </TableCell>
												<TableCell align={dir === 'rtl' ? 'right' : 'left'}>{material.qty}</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
					))}
				</div>
			</div>
			<Snackbar open={showError} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity='error'>
					{errorMessage}
				</Alert>
			</Snackbar>

			<Backdrop className={classes.backdrop} open={showBackDrop} onClick={handleCloseBackDrop}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	)
}
