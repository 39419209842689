import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import MuiDialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import MuiDialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography'
import AlertIcon from '../assets/svg/ss/bell.svg'
import lstrings from '../lstrings.js'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import Backdrop from '@material-ui/core/Backdrop'
import MeasureIcon from '../assets/svg/ss/measure-tape.svg'
import axios from 'axios'
import config from '../config.json'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import CircularProgress from '@material-ui/core/CircularProgress'

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />
}

function createData(slno, data, createddate) {
	return { slno, data, createddate }
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map(el => el[0])
}

function EnhancedTableHead(props) {
	const dir = document.getElementsByTagName('html')[0].getAttribute('dir')
	const setDir = dir === 'rtl' ? true : false

	const headCells = [
		{ id: 'slno', numeric: true, disablePadding: true, label: 'SL' },
		{ id: 'Setial', numeric: false, disablePadding: false, label: 'Serial' },
		{ id: 'Date', numeric: false, disablePadding: false, label: 'Date' }
	]

	const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}
	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align={!setDir ? 'left' : 'right'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
}

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	image: {
		position: 'absolute',
		left: theme.spacing(2),
		top: theme.spacing(2.5),
		color: theme.palette.grey[500]
	},
	textarea: {
		resize: 'both'
	},
	title: {
		marginLeft: 30
	}
})

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			{/* <img src={MeasureIcon} className={classes.image} width='25' alt="" /> */}
			<Typography className={classes.title} variant='h6'>
				{children}
			</Typography>
		</MuiDialogTitle>
	)
})

const DialogContent = withStyles(theme => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent)

const DialogActions = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions)

export default function SerialList(props) {
	const useStyles = makeStyles(theme => ({
		root: {
			width: 'calc(100%)'
		},
		paper: {
			width: '100%',
			marginBottom: theme.spacing(2),
			paddingLeft: 20,
			paddingRight: 20
		},
		papernew: {
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[2],
			display: 'flex',
			flexDirection: 'column',
			position: 'relative',
			outline: 'none',
			// padding: '10px 20px',
			width: '100%',
			borderRadius: '5px',
			overflow: 'auto',
			depth: 1
			// marginTop: '10px',
			// marginBottom: '10px',
		},
		grid: {
			padding: theme.spacing(2),
			textAlign: 'center',
			color: theme.palette.text.secondary,
			marginBottom: '10px'
		},
		inputFields: {
			marginTop: 10
		},
		submit: {
			display: 'flex',
			justifyContent: 'flex-end'
			// marginTop: '15px',
			// margin: '5px',
		},
		formControl: {
			marginTop: theme.spacing(1),
			minWidth: 120
		},
		selectEmpty: {
			marginTop: theme.spacing(2)
		}
	}))

	const classes = useStyles()

	const [open, setOpen] = React.useState(true)
	const [page, setPage] = React.useState(0)
	const [dense] = React.useState(true)
	const [order, setOrder] = React.useState('asc')
	const [orderBy, setOrderBy] = React.useState('calories')
	const [selected, setSelected] = React.useState([])

	const [rows, setRows] = React.useState([])
	const [totalCount, setTotalCount] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)

	const pageLimits = [10, 25, 50]
	// const pageLimits = [2, 4, 10];
	let offset = 0

	const [showError, setShowError] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState(null)

	const [name, set_name] = React.useState('')
	const [name_error, set_name_error] = React.useState(null)

	const [contactingServer, setContactingServer] = React.useState(false)

	const [showBackDrop, setShowBackDrop] = React.useState(false)

	useEffect(() => {
		console.log(props.data)
		getSerials(rowsPerPage)
	}, [])

	async function getSerials(numberOfRows) {
		console.log(props.data.material._id)
		console.log(props.warehouse)
		try {
			setShowBackDrop(true)
			let url =
				config['baseurl'] +
				'/api/serial/list?count=' +
				numberOfRows +
				'&warehouse=' +
				props.warehouse +
				'&offset=' +
				offset +
				'&material=' +
				props.data.material._id
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			const { data } = await axios.get(url)
			let newRows = []
			setTotalCount(data.totalDocs)
			const dateFns = new DateFnsUtils()
			for (let i = 0; i < data.list.docs.length; ++i) {
				//data.list[i].createddate_conv = dateFns.date(data.list.docs[i].createdDate);
				newRows.push(createData(offset + i + 1, data.list.docs[i], dateFns.date(data.list.docs[i].createdDate)))
			}

			setRows(newRows)

			setShowBackDrop(false)
		} catch (e) {
			setShowBackDrop(false)
			if (e.response) {
				setErrorMessage(e.response.data.message)
			} else {
				setErrorMessage('Error in getting list')
			}
			setShowError(true)
		}
	}

	const handleSave = async () => {
		try {
			setContactingServer(true)
			let url = config['baseurl'] + '/api/section/add'

			let postObj = {}
			postObj['name'] = name.trim()
			postObj['subdivision'] = props.subdivision._id

			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')

			const response = await axios.post(url, postObj)

			console.log('successfully Saved')
			setContactingServer(false)
			props.onNewSaved()
		} catch (e) {
			if (e.response) {
				console.log('Error in creating material')
				setErrorMessage(e.response.data['message'])
			} else {
				console.log('Error in creating')
				setErrorMessage('Error in creating: ', e.message)
			}
			setShowError(true)
			setContactingServer(false)
		}
	}

	const handleChangePage = (event, newPage) => {
		offset = newPage * rowsPerPage
		setPage(newPage)
		getSerials(rowsPerPage)
	}

	const handleChangeRowsPerPage = event => {
		const newRowsPerPage = parseInt(event.target.value, 10)
		setRowsPerPage(newRowsPerPage)
		setPage(0)
		offset = 0
		getSerials(newRowsPerPage)
	}

	const handleSelectAllClick = event => {}
	const handleRequestSort = (event, property) => {}
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setShowError(false)
	}
	const handleCloseBackDrop = () => {}

	return (
		<div>
			<Dialog fullWidth={true} onClose={props.noConfirmationDialogAction} aria-labelledby='customized-dialog-title' open={open}>
				<DialogTitle id='alert-dialog-title'>{'Serial List'}</DialogTitle>
				<DialogContent>
					<DialogContentText></DialogContentText>
					<Paper className={classes.grid}>
						<TableContainer>
							<Table
								className={classes.table}
								aria-labelledby='tableTitle'
								size={dense ? 'small' : 'medium'}
								aria-label='enhanced table'
							>
								<EnhancedTableHead
									classes={classes}
									numSelected={selected.length}
									order={order}
									orderBy={orderBy}
									onSelectAllClick={handleSelectAllClick}
									onRequestSort={handleRequestSort}
									rowCount={rows.length}
								/>
								<TableBody>
									{rows.map((row, index) => {
										const labelId = `enhanced-table-checkbox-${index}`
										return (
											<TableRow hover tabIndex={-1} key={row.slno}>
												<TableCell align={'left'} component='th' id={labelId} scope='row' padding='none'>
													{row.slno}
												</TableCell>
												<TableCell align={'left'}>{row.data.num}</TableCell>
												<TableCell align={'left'}>{row.createddate.toLocaleString()} </TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							rowsPerPageOptions={pageLimits}
							component='div'
							count={totalCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
						/>
					</Paper>
					{/* <DialogContentText id="alert-dialog-description">{props.message}</DialogContentText> */}
					{/* <form className={classes.papernew} autoComplete="off" noValidate>
                        <TextField className={classes.inputFields} id="formControl_name" defaultValue={name}
                            label="Name *" variant="outlined"
                            onChange={(event) => { set_name(event.target.value); set_name_error(null); }} />
                        {name_error && <Alert className={classes.alert} severity="error"> {name_error} </Alert>}
                    </form> */}
				</DialogContent>

				<DialogActions>
					<Button variant='contained' color='primary' onClick={props.closeAction} disabled={contactingServer}>
						Cancel
					</Button>
					{/* <Button style={{ marginLeft: 10 }} variant="contained" color="primary" onClick={handleSave} disabled={contactingServer}>Save</Button> */}
				</DialogActions>
			</Dialog>

			<Snackbar open={showError} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity='error'>
					{errorMessage}
				</Alert>
			</Snackbar>

			<Backdrop className={classes.backdrop} open={showBackDrop} onClick={handleCloseBackDrop}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	)
}
