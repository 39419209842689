import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import exhibitorsLogo from '../assets/svg/ss/exhibition.svg'
import notFoundImage from '../assets/svg/ss/page-not-found.svg'
import profileLogo from '../assets/svg/ss/profile.svg'
import Button from '@material-ui/core/Button'
import InputBase from '@material-ui/core/InputBase'
import SearchIcon from '@material-ui/icons/Search'
import EditImage from '@material-ui/icons/Edit'
import EnhancedTableToolbar from './enhancedToolbar'
import axios from 'axios'
import config from '../config.json'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import Image, { Shimmer } from 'react-shimmer'
import { useHistory } from 'react-router-dom'
import lstrings from '../lstrings'
import { SaveRounded, SkipNextOutlined } from '@material-ui/icons'
import TextField from '@material-ui/core/TextField'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import CustomInfoAlert from './CustomInfoAlert'
import DateFnsUtils from '@date-io/date-fns'
import { Typography } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />
}

function createData(slno, data) {
	return { slno, data }
}

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

function getComparator(order, orderBy) {
	return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map(el => el[0])
}

function EnhancedTableHead(props) {
	const dir = document.getElementsByTagName('html')[0].getAttribute('dir')
	const setDir = dir === 'rtl' ? true : false

	const headCells = [
		{ id: 'slno', numeric: true, disablePadding: true, label: 'SL' },
		{ id: 'name', numeric: false, disablePadding: false, label: 'User Name' },
		{ id: 'email', numeric: false, disablePadding: false, label: 'Coordinates' },
		{ id: 'roles', numeric: false, disablePadding: false, label: 'Roles' },
		{ id: 'action', numeric: false, disablePadding: false, label: 'Actions' }
	]
	const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props
	const createSortHandler = property => event => {
		onRequestSort(event, property)
	}
	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id}
						align={!setDir ? 'left' : 'right'}
						padding={headCell.disablePadding ? 'none' : 'default'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	classes: PropTypes.object.isRequired,
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
}

export default function MeterDataValidate(props) {
	const dir = document.getElementsByTagName('html')[0].getAttribute('dir')

	const useStyles = makeStyles(theme => ({
		root: {
			width: 'calc(100%)'
		},
		paper: {
			width: '100%',
			marginBottom: theme.spacing(2),
			paddingLeft: 20,
			paddingRight: 20
		},
		table: {
			minWidth: 750
		},
		visuallyHidden: {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1
		},
		grid: {
			padding: theme.spacing(2),
			textAlign: 'center',
			color: theme.palette.text.secondary,
			marginBottom: '10px'
		},
		total: {
			textAlign: 'left'
		},
		totalDetails: {
			marginRight: '20px'
		},
		totalAttendes: {
			display: 'flex',
			alignItems: 'baseline',
			width: '30%',
			// marginRight: '80px'
			// borderRight: "1px solid #CACACA",
			'@media (max-width: 600px)': {
				width: '300px'
			}
		},
		progress: {
			width: '70%',
			'@media (max-width: 600px)': {
				width: '600px'
			}
		},
		h1: {
			margin: '0px',
			paddingRight: '10px',
			paddingLeft: '10px'
		},
		vl: {
			border: '1px solid #CACACA',
			height: ' 40px'
		},
		search: {
			position: 'relative'
		},
		searchIcon: {
			height: '100%',
			position: 'absolute',
			display: 'flex',
			alignItems: 'center'
		},
		inputInput: {
			// width: '90%',
			paddingLeft: '30px',
			paddingRight: '30px',
			borderBottom: '1px solid #CACACA'
		},
		inputRoot: {
			width: '100%'
		},
		button: {
			background: 'white',
			textTransform: 'capitalize'
		},
		exhibitor_image: {
			marginRight: '10px'
		},
		flex: {
			display: 'flex',
			alignItems: 'center'
		},
		dot: {
			height: '10px',
			width: '10px',
			backgroundColor: '#bbb',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginLeft: '7px'
		},
		dotActive: {
			height: '10px',
			width: '10px',
			backgroundColor: '#4287F5',
			borderRadius: '50%',
			display: 'inline-block',
			marginRight: '7px',
			marginLeft: '7px'
		},
		addButton: {
			display: 'flex',
			alignItems: 'baseline',
			justifyContent: 'flex-end',
			width: '70%',
			// marginRight: '80px'
			// borderRight: "1px solid #CACACA",
			'@media (max-width: 600px)': {
				width: '300px'
			}
		},
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: '#fff'
		}
	}))

	const classes = useStyles()
	const [order, setOrder] = React.useState('asc')
	const [orderBy, setOrderBy] = React.useState('calories')
	const [selected, setSelected] = React.useState([])
	const [page, setPage] = React.useState(0)
	const [dense] = React.useState(true)
	const [showError, setShowError] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState(null)
	const [rows, setRows] = React.useState([])
	const [totalCount, setTotalCount] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(10)
	const [totalVisited, setTotalVisited] = React.useState(0)

	const [oldMeterImage, setOldMeterImage] = React.useState(null)
	const [newMeterImage, setNewMeterImage] = React.useState(null)
	const [billImage, setBillImage] = React.useState(null)

	const [oldMeterNumber, set_oldMeterNumber] = React.useState('')
	const [oldMeterNumber_error, set_oldMeterNumber_error] = React.useState(null)

	const [finalReading, set_finalReading] = React.useState('')
	const [finalReading_error, set_finalReading_error] = React.useState(null)

	const [newMeterNumber, set_newMeterNumber] = React.useState('')
	const [newMeterNumber_error, set_newMeterNumber_error] = React.useState(null)

	const [initialReading, set_initialReading] = React.useState('0')
	const [initialReading_error, set_initialReading_error] = React.useState(null)

	const [billNumber, set_billNumber] = React.useState('')
	const [billNumber_error, set_billNumber_error] = React.useState(null)

	const [accountID, set_accountID] = React.useState('')
	const [accountID_error, set_accountID_error] = React.useState(null)

	const [make_old_meter, set_make_old_meter] = React.useState('')
	const [make_new_meter, set_make_new_meter] = React.useState('L&T')

	const [capacityOldMeter, setCapacityOldMeter] = React.useState('')
	const [capacityNewMeter, setCapacityNewMeter] = React.useState('5-30A')

	const [showInfo, setShowInfo] = React.useState(false)

	const [showBackdrop, setShowBackdrop] = React.useState(false)

	const [divisions, setDivisions] = React.useState([])
	const [subDivisions, setSubDivisions] = React.useState([])
	const [sections, setSections] = React.useState([])
	const [activities, setActivities] = React.useState([])

	const [currentDivision, setCurrentDivision] = React.useState(-1)
	const [currentSubDivision, setCurrentSubDivision] = React.useState(-1)
	const [currentSection, setCurrentSection] = React.useState(-1)
	const [currentActivity, setCurrentActivity] = React.useState(-1)

	const [showSuccess, setShowSuccess] = React.useState(false)

	const history = useHistory()

	const pageLimits = [10, 25, 50]
	let offset = 0

	const [_data, set_data] = React.useState(null)
	const [_user, set_user] = React.useState(null)
	const [date, set_date] = React.useState(null)

	const getDivisionList = async () => {
		try {
			setShowBackdrop(true)
			console.log('page: ', page)
			let url = config['baseurl'] + '/api/division/list?count=' + 10000 + '&offset=' + 0 + '&search=' + ''
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			const { data } = await axios.get(url)
			// console.log(data);

			setDivisions(data.list.docs)
			setCurrentDivision(0)
			setShowBackdrop(false)
		} catch (e) {
			console.log(e.response)
			setShowBackdrop(false)
			if (e.response) {
				setErrorMessage(e.response.data.message)
			} else {
				setErrorMessage('Error in getting list')
			}
			console.log('Error in getting users list')
			setShowError(true)
		}
	}

	const getSubDivisionList = async () => {
		try {
			setShowBackdrop(true)
			console.log('page: ', page)
			const divisionIndex = currentDivision === -1 ? 0 : currentDivision
			let url =
				config['baseurl'] +
				'/api/subdivision/list?division=' +
				divisions[divisionIndex]._id +
				'&count=' +
				10000 +
				'&offset=' +
				0 +
				'&search=' +
				''
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			const { data } = await axios.get(url)
			// console.log(data);

			setSubDivisions(data.list.docs)
			setCurrentSubDivision(0)
			setShowBackdrop(false)
		} catch (e) {
			console.log(e.response)
			setShowBackdrop(false)
			if (e.response) {
				setErrorMessage(e.response.data.message)
			} else {
				setErrorMessage('Error in getting list')
			}
			console.log('Error in getting users list')
			setShowError(true)
		}
	}

	async function getSectionList(numberOfRows, search = '') {
		console.log('getSectionList called')
		try {
			setShowBackdrop(true)
			// console.log("page: ", page);

			const index = currentSubDivision === -1 ? 0 : currentSubDivision

			let url =
				config['baseurl'] +
				'/api/section/list?subdivision=' +
				subDivisions[index]._id +
				'&count=' +
				10000 +
				'&offset=' +
				0 +
				'&search=' +
				search
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			const { data } = await axios.get(url)
			// console.log(data);

			setSections(data.list.docs)
			setCurrentSection(0)
			setShowBackdrop(false)
		} catch (e) {
			console.log(e.response)
			setShowBackdrop(false)
			if (e.response) {
				setErrorMessage(e.response.data.message)
			} else {
				setErrorMessage('Error in getting list')
			}
			console.log('Error in getting users list')
			setShowError(true)
		}
	}

	async function getActivityList() {
		try {
			setShowBackdrop(true)
			let url = config['baseurl'] + '/api/activity/list?count=' + 10000 + '&offset=' + 0 + '&search=' + ''
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')
			const { data } = await axios.get(url)
			setActivities(data.list.docs)

			setShowBackdrop(false)
		} catch (e) {
			console.log(e.response)
			setShowBackdrop(false)
			if (e.response) {
				setErrorMessage(e.response.data.message)
			} else {
				setErrorMessage('Error in getting list')
			}
			console.log('Error in getting users list')
			setShowError(true)
		}
	}

	useEffect(() => {
		getDivisionList()
		getActivityList()
	}, [])

	useEffect(() => {
		if (divisions.length > 0) getSubDivisionList()
	}, [currentDivision])

	useEffect(() => {
		if (subDivisions.length > 0) getSectionList()
	}, [currentSubDivision])

	const handleChange = event => {
		setCurrentDivision(event.target.value)
	}

	const handleSubDivisionChange = event => {
		setCurrentSubDivision(event.target.value)
	}

	const handleSectionChange = event => {
		setCurrentSection(event.target.value)
	}

	const handleActivityChange = event => {
		setCurrentActivity(event.target.value)
	}

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setShowError(false)
	}

	const handleCloseSuccess = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setShowSuccess(false)
	}

	async function handleSave() {
		let nerrors = 0

		if (accountID.length == 0) {
			set_accountID_error('Should be 10 digit long only')
			nerrors += 1
		}

		if (oldMeterNumber.length == 0) {
			set_oldMeterNumber_error('Mandatory *')
			nerrors += 1
		}
		if (newMeterNumber.length == 0) {
			set_newMeterNumber_error('Mandatory *')
			nerrors += 1
		}
		if (billNumber.length == 0) {
			set_billNumber_error('Mandatory *')
			nerrors += 1
		}
		if (initialReading.length == 0) {
			set_initialReading_error('Mandatory *')
			nerrors += 1
		}
		if (finalReading.length == 0) {
			set_finalReading_error('Mandatory *')
			nerrors += 1
		}
		if (finalReading.length > 5) {
			set_finalReading_error('Cannot be bigger than 5 digits')
			nerrors += 1
		}
		if (nerrors > 0) {
			setErrorMessage('Enter all mandatory fields to save')
			setShowError(true)
			return
		}

		if (divisions.length == 0 || subDivisions.length == 0 || sections.length == 0) {
			setErrorMessage('Division, Subdivision and Section required')
			setShowError(true)
			return
		}

		if (checkNewMeterNumberIsValid(newMeterNumber.trim()) == false) {
			setErrorMessage('Fill all details properly')
			setShowError(true)
			return
		}

		setShowBackdrop(true)

		try {
			let url = config['baseurl'] + '/api/meterreplacementdata/addmanually'
			axios.defaults.headers.common['authToken'] = window.localStorage.getItem('authToken')

			let postObj = {}
			postObj['division'] = divisions[currentDivision]._id
			postObj['subdivision'] = subDivisions[currentSubDivision]._id
			postObj['section'] = sections[currentSection]._id
			postObj['activity'] = activities[currentActivity]._id

			postObj['billimagepath'] = 'https://smart-ex-files.s3.ap-south-1.amazonaws.com/uploads/datacollector/genericimage.jpg'
			postObj['oldmeterimagepath'] = 'https://smart-ex-files.s3.ap-south-1.amazonaws.com/uploads/datacollector/genericimage.jpg'
			postObj['newmeterimagepath'] = 'https://smart-ex-files.s3.ap-south-1.amazonaws.com/uploads/datacollector/genericimage.jpg'

			postObj['billNumber'] = billNumber.trim()
			postObj['oldMeterNumber'] = oldMeterNumber.trim()
			postObj['newMeterNumber'] = newMeterNumber.trim()

			postObj['initialReading'] = initialReading.trim()
			postObj['finalReading'] = finalReading.trim()
			postObj['accountID'] = accountID.trim()

			postObj['makeOldMeter'] = make_old_meter.trim()
			postObj['makeNewMeter'] = make_new_meter.trim()

			postObj['capacityOldMeter'] = capacityOldMeter.trim().toUpperCase()
			postObj['capacityNewMeter'] = capacityNewMeter.trim().toUpperCase()

			const updated = await axios.post(url, postObj)

			set_billNumber('')
			set_accountID('')
			set_oldMeterNumber('')
			set_newMeterNumber('')
			set_initialReading('')
			set_finalReading('')
			set_make_new_meter('')
			set_make_old_meter('')

			setShowBackdrop(false)

			setShowSuccess(true)

			console.log('successfully saved')
		} catch (e) {
			console.log(e)
			setShowBackdrop(false)
			if (e.response) {
				setErrorMessage(e.response.data.message)
			} else {
				setErrorMessage('Error in getting activities for project')
			}
			console.log('Error in getting activities for project')
			setShowError(true)
		}
	}

	const handleCloseBackDrop = () => {}

	const closeInfo = () => {
		setShowInfo(false)
		props.history.push('/meter-data-skipped')
	}

	const SetNewMeterNumberNow = str => {
		set_newMeterNumber(str)

		checkNewMeterNumberIsValid(str)
	}

	const checkNewMeterNumberIsValid = str => {
		if (str.match('^([A-z][0-9]+)$')) {
			if (str.length == 8) {
				set_newMeterNumber_error(null)
				return true
			} else set_newMeterNumber_error('Length should be 8 exactly')
		} else {
			set_newMeterNumber_error('Proper format is required')
		}

		return false
	}

	return (
		<div className={clsx(classes.root)}>
			<div className={classes.paper}>
				<EnhancedTableToolbar title={'Add Meter Data Manually'} />

				<Paper className={classes.grid} style={{ textAlign: 'left' }}>
					<span>Division: </span>
					<Select
						labelId='demo-simple-select-label'
						id='division-select'
						value={currentDivision === -1 ? '' : currentDivision}
						onChange={handleChange}
					>
						{divisions.map((row, index) => {
							return (
								<MenuItem key={'' + index} value={index}>
									{row.name}
								</MenuItem>
							)
						})}
					</Select>
					<span> SubDivision: </span>
					<Select
						labelId='demo-simple-select-label'
						id='subdivision-select'
						value={currentSubDivision === -1 ? '' : currentSubDivision}
						onChange={handleSubDivisionChange}
					>
						{subDivisions.map((row, index) => {
							return (
								<MenuItem key={'' + index} value={index}>
									{row.name}
								</MenuItem>
							)
						})}
					</Select>
					<span> Section: </span>
					<Select
						labelId='demo-simple-select-label'
						id='section-select'
						value={currentSection === -1 ? '' : currentSection}
						onChange={handleSectionChange}
					>
						{sections.map((row, index) => {
							return (
								<MenuItem key={'' + index} value={index}>
									{row.name}
								</MenuItem>
							)
						})}
					</Select>
					<span> Activity: </span>
					<Select
						labelId='demo-simple-select-label'
						id='activity-select'
						value={currentActivity === -1 ? '' : currentActivity}
						onChange={handleActivityChange}
					>
						{activities.map((row, index) => {
							return (
								<MenuItem key={'' + index} value={index}>
									{row.name}
								</MenuItem>
							)
						})}
					</Select>
				</Paper>

				<Paper className={classes.grid}>
					<Grid container spacing={1}>
						{/* <Grid item xs={6}>
                                <img src={billImage} width='400' alt="" />
                            </Grid> */}
						<Typography> RR/Bill</Typography>
						<Grid item xs={12}>
							<TextField
								size='small'
								className={classes.inputFields}
								id='formControl_billNumber'
								value={billNumber}
								label='RR Number *'
								variant='outlined'
								fullWidth
								inputProps={{ style: { textTransform: 'uppercase' } }}
								onChange={event => {
									set_billNumber(event.target.value)
									set_billNumber_error(null)
								}}
							/>
							{billNumber_error && (
								<Alert className={classes.alert} severity='error'>
									{' '}
									{billNumber_error}{' '}
								</Alert>
							)}
							<br></br>
							<br></br>
							<TextField
								size='small'
								className={classes.inputFields}
								id='formControl_accountID'
								value={accountID}
								label='Acccount ID *'
								variant='outlined'
								fullWidth
								inputProps={{ style: { textTransform: 'uppercase' } }}
								onChange={event => {
									set_accountID(event.target.value)
									set_accountID_error(null)
								}}
							/>
							{accountID_error && (
								<Alert className={classes.alert} severity='error'>
									{' '}
									{accountID_error}{' '}
								</Alert>
							)}
						</Grid>
					</Grid>
				</Paper>
				<Paper className={classes.grid}>
					<Grid container spacing={1}>
						{/* <Grid item xs={6}>
                                <img src={oldMeterImage} width='400' alt="" />
                            </Grid> */}
						<Typography> Old Meter </Typography>
						<Grid item xs={12}>
							<TextField
								size='small'
								className={classes.inputFields}
								id='formControl_oldMeterNumber'
								value={oldMeterNumber}
								label='Old Meter Number *'
								variant='outlined'
								fullWidth
								inputProps={{ style: { textTransform: 'uppercase' } }}
								onChange={event => {
									set_oldMeterNumber(event.target.value)
									set_oldMeterNumber_error(null)
								}}
							/>
							{oldMeterNumber_error && (
								<Alert className={classes.alert} severity='error'>
									{' '}
									{oldMeterNumber_error}{' '}
								</Alert>
							)}
							<br></br>
							<br></br>
							<TextField
								size='small'
								className={classes.inputFields}
								id='formControl_finalReading'
								value={finalReading}
								label='Final Reading *'
								variant='outlined'
								fullWidth
								inputProps={{ style: { textTransform: 'uppercase' } }}
								onChange={event => {
									set_finalReading(event.target.value)
									set_finalReading_error(null)
								}}
							/>
							{finalReading_error && (
								<Alert className={classes.alert} severity='error'>
									{' '}
									{finalReading_error}{' '}
								</Alert>
							)}
							<br></br>
							<br></br>
							<TextField
								size='small'
								className={classes.inputFields}
								id='formControl_make_old_meter'
								value={make_old_meter}
								label='Manufacturer'
								variant='outlined'
								fullWidth
								inputProps={{ style: { textTransform: 'uppercase' } }}
								onChange={event => {
									if (event.target.value.match(/^[A-Za-z!@#$%^&* ]+$/) !== null) {
										set_make_old_meter(event.target.value)
									}
								}}
							/>
							<br></br>
							<br></br>
							<TextField
								size='small'
								className={classes.inputFields}
								id='formControl_capactity_old_meter'
								defaultValue={capacityOldMeter}
								label='Capacity Old Meter'
								variant='outlined'
								fullWidth
								inputProps={{ style: { textTransform: 'uppercase' } }}
								onChange={event => {
									setCapacityOldMeter(event.target.value)
								}}
							/>
						</Grid>
					</Grid>
				</Paper>
				<Paper className={classes.grid}>
					<Grid container spacing={1}>
						{/* <Grid item xs={6}>
                                <img src={newMeterImage} width='400' alt="" />
                            </Grid> */}
						<Typography> New Meter </Typography>
						<Grid item xs={12}>
							<TextField
								size='small'
								className={classes.inputFields}
								id='formControl_newMeterNumber'
								value={newMeterNumber}
								label='New Meter Number *'
								variant='outlined'
								fullWidth
								inputProps={{ style: { textTransform: 'uppercase' } }}
								onChange={event => {
									SetNewMeterNumberNow(event.target.value)
								}}
							/>

							{/* onChange={(event) => { set_newMeterNumber(event.target.value); set_newMeterNumber_error(null); }} /> */}
							{newMeterNumber_error && (
								<Alert className={classes.alert} severity='error'>
									{' '}
									{newMeterNumber_error}{' '}
								</Alert>
							)}
							<br></br>
							<br></br>
							<TextField
								size='small'
								className={classes.inputFields}
								id='formControl_initialReading'
								value={initialReading}
								label='Initial Reading *'
								variant='outlined'
								fullWidth
								inputProps={{ style: { textTransform: 'uppercase' } }}
								onChange={event => {
									set_initialReading(event.target.value)
									set_initialReading_error(null)
								}}
							/>
							{initialReading_error && (
								<Alert className={classes.alert} severity='error'>
									{' '}
									{initialReading_error}{' '}
								</Alert>
							)}
							<br></br>
							<br></br>
							<TextField
								size='small'
								className={classes.inputFields}
								id='formControl_make_new_meter'
								value={make_new_meter}
								label='Manufacturer'
								variant='outlined'
								fullWidth
								inputProps={{ style: { textTransform: 'uppercase' } }}
								onChange={event => {
									set_make_new_meter(event.target.value)
								}}
							/>
							<br></br>
							<br></br>
							<TextField
								size='small'
								className={classes.inputFields}
								id='formControl_capactity_new_meter'
								defaultValue={capacityNewMeter}
								label='Capacity New Meter'
								variant='outlined'
								fullWidth
								inputProps={{ style: { textTransform: 'uppercase' } }}
								onChange={event => {
									setCapacityNewMeter(event.target.value)
								}}
							/>
						</Grid>
					</Grid>
				</Paper>
				<Paper className={classes.grid}>
					<div>
						{/* <Button onClick={() => handleCancel()} color="primary" className={classes.button}><SkipNextOutlined />Cancel</Button> */}
						<Button onClick={() => handleSave()} color='primary' className={classes.button}>
							<SaveRounded />
							Save
						</Button>
					</div>
				</Paper>
			</div>

			{showInfo && <CustomInfoAlert close={closeInfo} title='SmartEx' message='Not data available' />}

			<Snackbar open={showError} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity='error'>
					{errorMessage}
				</Alert>
			</Snackbar>

			<Snackbar open={showSuccess} autoHideDuration={6000} onClose={handleCloseSuccess}>
				<Alert onClose={handleCloseSuccess} severity='success'>
					{'Succcessfully Saved!'}
				</Alert>
			</Snackbar>

			<Backdrop className={classes.backdrop} open={showBackdrop} onClick={handleCloseBackDrop}>
				<CircularProgress color='inherit' />
			</Backdrop>
		</div>
	)
}
